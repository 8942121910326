function getMobileDeviceInfo() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    return {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent),
        isAndroid: /Android/i.test(userAgent),
        isiOS: /iPhone|iPad|iPod/i.test(userAgent),
    };
}
function ensureRedierct(target) {
   const {isMobile} = getMobileDeviceInfo()
   const isMatch = isMobile ? target == 'mobile' : target == 'web'
   if (!isMatch) {
        const redirect = target == 'web' ? '/mpcmobile.html' : 'mpc.html';
        window.location.href = redirect
   }
}